import * as Icons from "../plugins/page/icons";

import { GroupOptions, InputSpecGroup } from "../form-specs/index";

import { IconColoring } from "../form-specs";
import { getI18nLocaleObject } from "../i18n";
import namespaceList from "../i18n/namespaceList";

export interface ButtonConfigurationOptions {
    buttonColor: ButtonColor;
    buttonSize: ButtonSize;
    usePreConfiguredButton: boolean;
    preConfiguredButton: PreConfiguredButton;
    buttonTxtColor: string;
    showIcons: boolean;
    iconProperties: string;
    iconColor: IconColoring;
    iconPosition: IconPosition;
    iconRight: boolean;
    displayLink: boolean;
    linkHoverEffect: LinkHoverEffect;
    linkColor: IconColoring;
    useAsBorderedButton: boolean;
}

// Types

export type ButtonColor = "button--primary" | "button-inverse" | "button--secondary" | "button--ghost" | "button--brand-alternative";
export type ButtonSize = "button--s" | "button--m" | "button--l" | "button--xl" | "button--round";
export type IconPosition = "" | "top" | "center" | "bottom";
export type PreConfiguredButton = "button-primary" | "button-secondary";
export type LinkHoverEffect = "" | "he-1" | "he-2";

export const iconList = Object.keys(Icons.default).map((key: any) => ({ label: (Icons.default as any)[key], value: key }));

// Labels
export const I18N_BUTTON_COLOR = getI18nLocaleObject(namespaceList.widgetWebContent, "color");
export const I18N_USE_PRE_CONFIGURED_BUTTON = getI18nLocaleObject(namespaceList.admin, "usePreConfiguredButton");
export const I18N_PRE_CONFIGURED_BUTTON = getI18nLocaleObject(namespaceList.admin, "usePreConfiguredButton");
export const I18N_DISPLAY_LINK = getI18nLocaleObject(namespaceList.dynamicButtonWidget, "displayLink");
export const I18N_LINK_COLOR = getI18nLocaleObject(namespaceList.dynamicButtonWidget, "linkColor");
// to do
export const I18N_LINK_HOVER_EFFECT = getI18nLocaleObject(namespaceList.dynamicButtonWidget, "linkhover");
export const I18N_SHOW_ICON = getI18nLocaleObject(namespaceList.widgetWebContent, "iconTitle");
export const I18N_ICON_RIGHT = getI18nLocaleObject(namespaceList.widgetWebContent, "iconToRight");
export const I18N_ICON_PROPERTIES = getI18nLocaleObject(namespaceList.widgetWebContent, "iconProperty");
export const I18N_ICON_COLOR = getI18nLocaleObject(namespaceList.widgetWebContent, "iconColor");
export const I18N_BUTTON_TEXT_COLOR = getI18nLocaleObject(namespaceList.widgetWebContent, "buttonTxtColor");
export const I18N_BUTTON_SIZE = getI18nLocaleObject(namespaceList.widgetWebContent, "size");
export const I18N_USE_AS_BORDERED_BUTTON = getI18nLocaleObject(namespaceList.dynamicButtonWidget, "borderedButton");

export enum LinkHoverEffectOptions {
    OPACITY = "Show a slightly ligther color of the link",
    UNDERLINE = "Show an underline from left to right",
}

/* eslint-disable max-lines-per-function */
export const ButtonConfigurationSpec = <E, P extends keyof E>(options: GroupOptions<E, P>): InputSpecGroup<E, P, ButtonConfigurationOptions, keyof ButtonConfigurationOptions> => {
    const { variable, visible } = options;
    return {
        variable,
        visible,
        type: "group",
        groupName: options?.groupName,
        groupTitle: options?.groupTitle,
        groupDescription: options?.groupDescription,
        specs: [
            {
                variable: "usePreConfiguredButton",
                label: I18N_USE_PRE_CONFIGURED_BUTTON,
                type: "checkbox",
                visible: (options: ButtonConfigurationOptions) => !options?.displayLink,
            },
            {
                variable: "displayLink",
                label: I18N_DISPLAY_LINK,
                type: "checkbox",
                visible: (options: ButtonConfigurationOptions) => !options?.usePreConfiguredButton && !options?.useAsBorderedButton,
            },
            {
                variable: "linkHoverEffect",
                label: I18N_LINK_HOVER_EFFECT,
                type: "select",
                default: "" as LinkHoverEffect,
                optionList: [
                    {
                        value: "he-1",
                        label: LinkHoverEffectOptions.UNDERLINE,
                    },
                    {
                        value: "he-2",
                        label: LinkHoverEffectOptions.OPACITY,
                    },
                ],

                visible: (options: ButtonConfigurationOptions) => !options?.usePreConfiguredButton && options?.displayLink,
            },
            {
                variable: "preConfiguredButton",
                label: I18N_PRE_CONFIGURED_BUTTON,
                type: "select",
                default: "button-primary" as PreConfiguredButton,
                optionList: [
                    {
                        value: "button-primary",
                        label: getI18nLocaleObject(namespaceList.widgetPrevNext, "btnPrimary"),
                    },
                    {
                        value: "button-secondary",
                        label: getI18nLocaleObject(namespaceList.widgetPrevNext, "btnSecondary"),
                    },
                ],
                visible: (options: ButtonConfigurationOptions) => options?.usePreConfiguredButton,
            },
            {
                variable: "buttonColor",
                label: I18N_BUTTON_COLOR,
                type: "dual-color",
                default: "button--primary" as ButtonColor,
                visible: (options: ButtonConfigurationOptions) => !options?.usePreConfiguredButton && !options?.displayLink,
            },
            {
                variable: "buttonTxtColor",
                label: I18N_BUTTON_TEXT_COLOR,
                type: "dual-color",
                default: "#FFFFFF",
                visible: (options: ButtonConfigurationOptions) => !options?.displayLink && !options?.usePreConfiguredButton,
            },
            {
                variable: "useAsBorderedButton",
                label: I18N_USE_AS_BORDERED_BUTTON,
                type: "checkbox",
                visible: (options: ButtonConfigurationOptions) => !options?.usePreConfiguredButton && !options?.displayLink,
            },
            {
                variable: "buttonSize",
                label: I18N_BUTTON_SIZE,
                type: "select",
                default: "button--m" as ButtonSize,
                optionList: [
                    {
                        value: "button--s",
                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "button-s"),
                    },
                    {
                        value: "button--m",
                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "button-m"),
                    },
                    {
                        value: "button--l",
                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "button-l"),
                    },
                    {
                        value: "button--xl",
                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "button-xl"),
                    },
                    {
                        value: "button--round",
                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "rounded"),
                    },
                ],
                visible: (options: ButtonConfigurationOptions) => !options?.usePreConfiguredButton && !options?.displayLink,
            },
            {
                variable: "linkColor",
                label: I18N_LINK_COLOR,
                type: "dual-color",
                default: "color-brand" as IconColoring,
                visible: (options: ButtonConfigurationOptions) => options?.displayLink && !options.usePreConfiguredButton,
            },
            // Icons
            {
                variable: "showIcons",
                label: I18N_SHOW_ICON,
                type: "checkbox",
            },
            {
                variable: "iconRight",
                label: I18N_ICON_RIGHT,
                type: "checkbox",
                visible: (options: ButtonConfigurationOptions) => options?.showIcons,
            },
            {
                variable: "iconColor",
                label: I18N_ICON_COLOR,
                type: "dual-color",
                default: "color-brand-alt" as IconColoring,
                visible: (options: ButtonConfigurationOptions) => options?.showIcons,
            },
            {
                variable: "iconProperties",
                label: I18N_ICON_PROPERTIES,
                type: "select",
                visible: (options: ButtonConfigurationOptions) => options?.showIcons,
                optionList: iconList,
            },
            {
                variable: "iconProperties",
                type: "icons",
                visible: (options: ButtonConfigurationOptions) => options?.showIcons,
            },
        ],
    };
};

export const defaultButtonConfigurationValues: ButtonConfigurationOptions = {
    buttonColor: "button--primary",
    buttonSize: "button--m",
    usePreConfiguredButton: false,
    preConfiguredButton: "button-primary",
    buttonTxtColor: "#FFF",
    showIcons: false,
    iconProperties: "",
    iconColor: "color-brand",
    iconPosition: "top",
    iconRight: false,
    displayLink: false,
    linkColor: "color-brand",
    linkHoverEffect: "",
    useAsBorderedButton: false,
};
