import * as React from "react";

import { ACTIVITY, DYNAMIC_FIELD_CODE, allSites, contentTypeSelector, descriptionTypeMultiSelector, dynamicFieldPrice, resortMultiSelector } from "../../../components/utils";
import { ButtonConfigurationOptions, ButtonConfigurationSpec, defaultButtonConfigurationValues } from "../../../utils/button.util";
import { DynamicSearchButtonWidget, warmpupCache } from "./DynamicSearchButton";
import { FormSpec, getHelpId, localized, multiSelectStylePicker, templateSpec } from "../../../form-specs";
import { MultiSelectOptionNumberValue, WithId } from "@maxxton/cms-api";
import { resourcesAutocomplete, unitsAutocomplete } from "../../../inputSpecs";

import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { PageWidgetSpec } from "../../widget";
import { WidgetGroup } from "../../widget.enum";
import activitiesAutocomplete from "../../../inputSpecs/activityAutocomplete";
import { autocompleteSiteSpec } from "../../../form-specs/models/autocompleteSite";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { isServerSide } from "../../../utils/generic.util";
import namespaceList from "../../../i18n/namespaceList";
import { pageLink } from "../../../routing";
import { pageSpec } from "../../../form-specs/models/page";

export interface WidgetOptions extends WithId {
    styleIds: any[];
    localized: LocalizedButton[];
    showResult: boolean;
    buttonConfiguration: ButtonConfigurationOptions;
    enableDefaultURL?: boolean;
    useAsCloseButton?: boolean;
    showConditionalContent?: boolean;
    useForMultipleAccoTypes?: boolean;
    conditionalTemplateId?: string;
    useDynamicFieldAsLink?: boolean;
    descriptionTypeMultiSelector?: NumberMultiSelectOption[];
    dynamicFieldCodes?: NumberMultiSelectOption[];
    openInNewTab?: boolean;
    contentTypeSelector?: boolean;
    contentType?: string;
    resortMultiSelector?: NumberMultiSelectOption[];
    resourceId?: number;
    unitId?: number;
    activityIds?: NumberMultiSelectOption[];
    useAsCartButton?: boolean;
    disableStateUuid?: boolean;
    useAsDynamicBookUrlLink?: boolean;
}

export interface LocalizedButton {
    locale: string;
    buttonText: string;
    useExternalUrl: boolean;
    externalUrl: string;
    siteId: string;
    pageId: string;
    labelBeforeCount: string;
    labelAfterCount: string;
    openNewTab: boolean;
}

const TARGETS = ["button", "button-model"];

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "dynamicSearchButton-widget-options",
    name: getI18nLocaleObject(namespaceList.dynamicSearchButtonWidget, "dynamicSearchButtonWidgetOption"),
    pluralName: getI18nLocaleObject(namespaceList.dynamicSearchButtonWidget, "dynamicSearchButtonWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetButton, "general"),
                    help: getHelpId("4cVbSx-general"),
                    properties: [
                        [
                            {
                                variable: "useDynamicFieldAsLink",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "useDynamicFieldAsLink"),
                                type: "checkbox",
                            },
                            contentTypeSelector("contentType", true, undefined, undefined, "useDynamicFieldAsLink"),
                            resortMultiSelector("resortMultiSelector"),
                            resourcesAutocomplete("resourceId"),
                            unitsAutocomplete("unitId"),
                            activitiesAutocomplete("activityIds"),
                            descriptionTypeMultiSelector("descriptionTypeMultiSelector", undefined, true, "useDynamicFieldAsLink"),
                            {
                                ...dynamicFieldPrice("dynamicFieldCodes", undefined, undefined, "Activity"),
                                visible: (options: WidgetOptions) =>
                                    !!options.descriptionTypeMultiSelector?.find((check: MultiSelectOptionNumberValue) => check.value === DYNAMIC_FIELD_CODE) &&
                                    options.contentType === ACTIVITY &&
                                    !!options.useDynamicFieldAsLink,
                            },
                            {
                                ...dynamicFieldPrice("dynamicFieldCodes"),
                                visible: (options: WidgetOptions) =>
                                    !!options.descriptionTypeMultiSelector?.find((check: MultiSelectOptionNumberValue) => check.value === DYNAMIC_FIELD_CODE) &&
                                    options.contentType !== ACTIVITY &&
                                    !!options.useDynamicFieldAsLink,
                            },

                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "useExternalUrl",
                                        label: getI18nLocaleObject(namespaceList.admin, "useExtUrl"),
                                        type: "checkbox",
                                        default: false,
                                    },
                                    {
                                        variable: "externalUrl",
                                        label: getI18nLocaleObject(namespaceList.admin, "externalUrl"),
                                        type: "text",
                                        default: "",
                                        visible: (options: any) => options.useExternalUrl,
                                    },
                                    {
                                        variable: "buttonText",
                                        label: getI18nLocaleObject(namespaceList.widgetButton, "buttonText"),
                                        type: "text",
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetImage, "site"),
                                        variable: "siteId",
                                        type: "autocomplete",
                                        default: "",
                                        refType: autocompleteSiteSpec,
                                        visible: (options: any) => !options.useExternalUrl,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetImage, "page"),
                                        variable: "pageId",
                                        type: "autocomplete",
                                        default: "",
                                        refType: pageSpec,
                                        dependsOnSiteSpec: "siteId",
                                        visible: (options: any) => !options.useExternalUrl,
                                    },
                                    {
                                        variable: "openNewTab",
                                        label: getI18nLocaleObject(namespaceList.admin, "openNewTab"),
                                        type: "checkbox",
                                        default: false,
                                    },
                                ],
                                visible: (options: WidgetOptions) => !options?.useDynamicFieldAsLink,
                            }),
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "buttonText",
                                        label: getI18nLocaleObject(namespaceList.widgetButton, "buttonText"),
                                        type: "text",
                                    },
                                    {
                                        variable: "openNewTab",
                                        label: getI18nLocaleObject(namespaceList.admin, "openNewTab"),
                                        type: "checkbox",
                                        default: false,
                                    },
                                ],
                                visible: (options: WidgetOptions) => !!options?.useDynamicFieldAsLink,
                            }),
                            {
                                variable: "enableDefaultURL",
                                label: getI18nLocaleObject(namespaceList.admin, "enableDefaultURL"),
                                type: "checkbox",
                                default: false,
                            },
                            {
                                variable: "useAsCloseButton",
                                label: getI18nLocaleObject(namespaceList.admin, "useAsCloseButton"),
                                type: "checkbox",
                                default: false,
                            },
                            {
                                variable: "showConditionalContent",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "showConditionalContent"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !options.useDynamicFieldAsLink,
                            },
                            {
                                variable: "useAsCartButton",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "useAsCartButton"),
                                type: "checkbox",
                                default: false,
                            },
                            {
                                variable: "disableStateUuid",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "disableStateUuid"),
                                type: "checkbox",
                                default: false,
                            },
                            {
                                variable: "useAsDynamicBookUrlLink",
                                label: getI18nLocaleObject(namespaceList.admin, "useAsDynamicBookUrlLink"),
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetButton, "styling"),
                    help: getHelpId("4cVbSx-styling"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            ButtonConfigurationSpec<WidgetOptions, keyof WidgetOptions>({ variable: "buttonConfiguration" }),
                            {
                                variable: "showResult",
                                label: getI18nLocaleObject(namespaceList.dynamicSearchButtonWidget, "showResult"),
                                type: "checkbox",
                            },
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "labelBeforeCount",
                                        label: getI18nLocaleObject(namespaceList.dynamicSearchButtonWidget, "labelBeforeCount"),
                                        type: "text",
                                    },
                                    {
                                        variable: "labelAfterCount",
                                        label: getI18nLocaleObject(namespaceList.dynamicSearchButtonWidget, "labelAfterCount"),
                                        type: "text",
                                    },
                                ],
                                visible: (options: WidgetOptions) => options.showResult,
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "conditionalUsage"),
                    properties: [
                        [
                            {
                                variable: "useForMultipleAccoTypes",
                                label: getI18nLocaleObject(namespaceList.admin, "useForMultipleAccoTypes"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !!options.showConditionalContent,
                            },
                            {
                                variable: "conditionalTemplateId",
                                label: getI18nLocaleObject(namespaceList.widgetTemplate, "template"),
                                type: "autocomplete",
                                refType: templateSpec,
                                visible: (options: WidgetOptions) => !!options.useForMultipleAccoTypes,
                            },
                        ],
                    ],
                    visible: (options: WidgetOptions) => !options.useDynamicFieldAsLink,
                },
            ],
        },
    ],
};

export const dynamicSearchButtonWidget: PageWidgetSpec<WidgetOptions> = {
    id: "dynamicSearchButtonWidget",
    type: "page",
    widgetGroup: WidgetGroup.DYNAMIC,
    name: getI18nLocaleObject(namespaceList.dynamicSearchButtonWidget, "dynamicSearchButtonWidget"),
    description: getI18nLocaleObject(namespaceList.dynamicSearchButtonWidget, "dynamicSearchButtonWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): Omit<WidgetOptions, "_id"> => ({
        styleIds: [],
        localized: [],
        showResult: false,
        buttonConfiguration: defaultButtonConfigurationValues,
    }),
    async render(widget, context) {
        const { currentLocale, site } = context;
        const { styleIds, useDynamicFieldAsLink } = widget.options;
        const localContent = getLocalizedContent({ site, currentLocale, localizedContent: widget.options.localized });
        const className = findMultiSelectStyleClassNames(context.theme, TARGETS, styleIds);
        let url = "";
        if (localContent && localContent.siteId && !useDynamicFieldAsLink) {
            let site;
            if (allSites.length) {
                site = allSites.find((item) => item._id === localContent.siteId);
            } else {
                site = context.site._id === localContent.siteId ? context.site : await context.cmsApi.siteApi.findById({ id: localContent.siteId, projection: { sitemap: 0 } });
            }
            if (site && localContent.pageId && !localContent.useExternalUrl) {
                url = (await pageLink({ site, pageId: localContent.pageId, locale: context.currentLocale, context })) || "";
            } else if (localContent.useExternalUrl) {
                url = localContent.externalUrl;
            }
        }
        const target = (!localContent?.useExternalUrl && localContent?.openNewTab) || (localContent?.useExternalUrl && localContent.openNewTab) ? "_blank" : "_self";
        if (isServerSide()) {
            await warmpupCache({
                options: widget.options,
                context,
                className,
                url,
                target,
                dynamicFilter: context.reduxStore.store.getState().dynamicFilter,
                availabilityState: {},
                myEnvState: {},
            });
        }
        return <DynamicSearchButtonWidget options={widget.options} context={context} className={className} url={url} target={target} />;
    },
};
